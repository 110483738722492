/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { TaskTableToolbar } from '../../sections/@dashboard/task/list';
// eslint-disable-next-line import/no-unresolved
import { setCount } from 'src/redux/actions/reviewCount';
import ReviewTaskRow from 'src/sections/@dashboard/task/list/ReviewTaskRow';
import KanbanWatchPopup from 'src/sections/@dashboard/kanban/kanban-watchDetail';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

const TABLE_HEAD = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },
  { id: 'priority', label: 'priority', align: 'center' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'dev', label: 'Developer', align: 'left' },
  { id: 'parent', label: '', align: 'left' },
  { id: 'allotedTime', label: '', align: 'left' },
  //   { id: 'action', label: 'Action', align: 'left' },
];

export default function WatchTask(task) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const [openWatchPopup, setOpenWatchPopup] = useState(false);

  const navigate = useNavigate();
  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const [tableData, setTableData] = useState([]);

  const [filterTitle, setFilterTitle] = useState('');

  const [filterDeadline, setFilterDeadline] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [count1, setCount1] = useState(0);
  const [inTestingTasksCount, setInTestingTasksCount] = useState(null);
  const [selectedTask, setSelectedTask] = useState({});
  const dispatch = useDispatch();
  const { user } = userDetails;

  const handleFilterName = (filterTitle) => {
    setFilterTitle(filterTitle);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterDeadline(event.target.value);
  };

  const handleWatchingOpen = (task) => {
    setSelectedTask(task);
    if (user?.role_id === 1 || user?.role_id === 3) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };

  const handleWatchClose = () => {
    setSelectedTask({});
    setOpenWatchPopup(false);
  };

  const getTasks = async () => {
    setLoading(true);
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll(
      'get',
      `/tasks/watchlist?page=${page + 1}&query=${filterTitle}`,
      // `/tasks?type=reviewers_task&page=${page + 1}&query=${filterTitle}`,
      {},
      token
    );
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTableData(data?.data);
      setCount1(response?.data?.total);
      getCounts();
    }
  };
  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;
    dispatch(setCount(data));
    setInTestingTasksCount(data.in_testing_tasks_count);
  };

  useEffect(() => {
    getCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasks();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterTitle]);

  useEffect(() => {
    getTasks();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterTitle]);

  const handleEditRow = (id) => {
    navigate(`/tasks/edit/${id}`);
  };
  const handleAddSub = (id) => {
    navigate(`/tasks/add-sub/${id}`);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData.length && !!filterTitle) ||
    (!tableData.length && !!filterDeadline) ||
    (!tableData.length && !!filterStatus);

  const WatchButton = <Button variant="outlined">Watching</Button>;

  // const isWatching = (task) => {
  //   if (user?.roles[0]?.id === 1 || user?.roles[0]?.id === 3) {
  //     return <div>{task?.watchers?.some((item) => item.id === user?.id) ? WatchButton : watchTaskDetail}</div>;
  //   }
  // };

  return (
    <Page title="Testing tasks : List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Watch List"
          links={[{ name: 'Tasks', href: '/kanban' }, { name: 'Watching List' }]}
        />

        <Card>
          <Divider />

          <TaskTableToolbar
            filterTitle={filterTitle}
            filterDeadline={filterDeadline}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
            search="task"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData.map((row) => (
                      <TableRow key={row.task.id}>
                        <TableCell>{row.task.title}</TableCell>
                        <TableCell>{row.task.project.title}</TableCell>
                        <TableCell>{row.task.due_date}</TableCell>
                        <TableCell>{row.task.task_priority.name}</TableCell>
                        <TableCell>{row.task.task_status.name}</TableCell>
                        <TableCell>{row.task.task_users[0]?.user.full_name}</TableCell>
                        <TableCell onClick={() => handleWatchingOpen({ id: row.task.id })}>{WatchButton}</TableCell>
                      </TableRow>
                    ))}
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
          <KanbanWatchPopup
            open={selectedTask?.id}
            onClose={handleWatchClose}
            task={selectedTask}
            callback={getTasks}
            rewatch
          />
        </Card>
      </Container>
    </Page>
  );
}
WatchTask.propTypes = {
  task: PropTypes.object,
};
