import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { useLocation } from 'react-router-dom';
// @mui
import {
  Avatar,
  Button,
  Card,
  Container,
  Divider,
  Table,
  TableBody,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-duplicates

// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import FilterModal from 'src/components/filterModal/indx';
import { setCount } from 'src/redux/actions/reviewCount';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { LogTableRow } from '../../sections/@dashboard/logs/list';
import TimeSheet from '../timeSheet';
import EditLogsForm from './EditLog';
import AddLogsForm from './addLogs';

const TABLE_HEAD = [
  { id: 'id', label: 'Log Type', align: 'left' },
  { id: 'type', label: 'Project', align: 'left' },
  { id: 'task_title', label: 'Title', align: 'left' },
  { id: 'work', label: 'Work', align: 'left' },
  { id: 'time', label: 'Time', align: 'center' },
  { id: 'start_time', label: 'Start', align: 'left' },
  { id: 'end_time', label: 'End', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'action', label: 'Edit', align: 'left' },
];

const LOG_TYPES = [
  { VALUE: 'all', LABEL: 'All' },
  { VALUE: 'task_log', LABEL: 'Task Logs' },
  { VALUE: 'discussion', LABEL: 'Discussion' },
  { VALUE: 'others', LABEL: 'Other' },
];

const SORT_OPTIONS = [
  { value: 'default', label: 'Default' },
  { value: 'ASC', label: 'Ascending' },
  { value: 'DESC', label: 'Descending' },
];

// ----------------------------------------------------------------------

export default function Logs() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails, userList, projectList, teamUserList } = useSelector((state) => state.loginReducer);

  const { token } = userDetails;
  const [isLogShow, setIsLogShow] = useState('list');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const taskID = urlParams.get('task_id') || '';
  const date = urlParams.get('date');
  const type = urlParams.get('type');

  const location = useLocation();
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [filterId, setFilterId] = useState('');
  const [total, setTotal] = useState(0);
  const [editTask, setEditTask] = useState();
  const [dateFilter, setDate] = useState(date || moment(new Date()).format('YYYY-MM-DD'));
  const [filterWork, setFilterWork] = useState('all');
  const [userID, setUserID] = useState('');
  const [query, setQuery] = useState();
  const [project, setProject] = useState();
  const [users, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const { setColor } = useSettings();
  const [totalTime, setTotalTime] = useState('');
  const [totalMin, setTotalMin] = useState(0);
  const dispatch = useDispatch();
  const loglimit = 100;
  const [logType, setLogType] = useState('all'); // Add this new state
  const [sortOrder, setSortOrder] = useState('default');

  const handleFilterName = (filterId) => {
    setFilterId(filterId);
    setPage(0);
  };

  useEffect(() => {
    setUserID(userDetails?.user?.id);
  }, [userDetails.user]);

  const handleFilterRole = (event) => {
    setFilterWork(event.target.value);
  };

  const getLogesLead = async () => {
    if (!userID) return;
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${
        page + 1
      }&project_id=${projectId}&type=team_log&user_id=${userID}&log_type=${logType !== 'all' ? logType : ''}&date=${
        (dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''
      }&limit=${loglimit}${sortOrder !== 'default' ? `&sort=true&sort_by=${sortOrder}` : ''}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      // setAllTask(response?.data?.data?.data);
      setTableData(response?.data?.data?.data);
      getCounts();
      setTotal(response?.data?.data?.total);
      setTotalTime(response?.data?.data?.total_time);
    } else {
      setTotal();
      setLoading(false);
    }
  };
  const getLoges = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${page + 1}&project_id=${projectId}&user_id=${userID}&log_type=${
        logType !== 'all' ? logType : ''
      }&date=${(dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''}&limit=${loglimit}${
        sortOrder !== 'default' ? `&sort=true&sort_by=${sortOrder}` : ''
      }`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      setTableData(response?.data?.data?.data);
      getCounts();
      setTotal(response?.data?.data?.total);
    } else {
      setTotal();
      setLoading(false);
    }
  };
  const getCounts = async () => {
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;
    dispatch(setCount(data));
    // }
  };

  const handleEditRow = (data) => {
    setEditOpen(true);
    setEditTask(data);
  };

  const denseHeight = dense ? 52 : 72;

  const handleAddOpen = () => {
    setOpen(true);
    setEditTask('');
  };

  const isNotFound =
    (!tableData?.length && !!filterId) ||
    (!tableData?.length && !!filterWork) ||
    (!tableData?.length && !!filterStatus);

  useEffect(() => {
    if (userDetails?.user?.roles[0]?.id === 2) {
      getLoges(dateFilter);
    } else {
      getLogesLead(dateFilter);
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskID, date, open, editOpen, query, projectId, dateFilter, setDate, userID, page, logType, sortOrder]);

  useEffect(() => {
    if (dateFilter) {
      const key = 'date';
      const value = dateFilter;
      const val = 'time';
      const total = tableData.reduce((accumulator, currentValue) => {
        if (currentValue[key] === value) {
          return accumulator + currentValue[val];
        }
        return accumulator;
      }, 0);
      setTotalMin(total);
    } else {
      let startdate = moment();
      startdate = startdate.format('YYYY-MM-DD');

      const key = 'date';
      const value = startdate;
      const val = 'time';
      const total = tableData.reduce((accumulator, currentValue) => {
        if (currentValue[key] === value) {
          return accumulator + currentValue[val];
        }
        return accumulator;
      }, 0);
      setTotalMin(total);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, dateFilter]);

  function padToTwoDigits(num) {
    return num.toString().padStart(2, '0');
  }
  const handleToggle = (e, val) => {
    if (val === 'sheet') {
      setIsLogShow('sheet');
    } else if (val === 'list') {
      setIsLogShow('list');
    }
  };

  const handleLogTypeChange = (event) => {
    setLogType(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setPage(0);
  };

  return (
    <Page title="Logs">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isLogShow === 'list' ? 'Logs List' : 'Time Sheet'}
          links={[{ name: 'Tasks', href: '/kanban' }, { name: isLogShow === 'list' ? 'Logs' : 'Time Sheet' }]}
          action={
            <>
              <ToggleButtonGroup
                color="primary"
                value={isLogShow}
                exclusive
                onChange={handleToggle}
                aria-label="Platform"
              >
                <ToggleButton value="list">List View</ToggleButton>
                <ToggleButton value="sheet">Calendar View</ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="contained"
                sx={{ ml: '0.5rem' }}
                onClick={handleAddOpen}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add Logs
              </Button>
            </>
          }
        />
        {isLogShow === 'list' ? (
          <>
            <div
              style={{ marginBottom: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tableData?.length > 0 && (
                  <>
                    <Avatar
                      alt={`${tableData[0]?.user?.first_name} ${tableData[0]?.user?.last_name}`}
                      src={tableData[0]?.user?.profile_picture}
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="subtitle2" noWrap sx={{ mr: 4 }}>
                      {`${tableData[0]?.user?.first_name} ${tableData[0]?.user?.last_name}`}
                    </Typography>
                  </>
                )}
                <div>
                  <span className="time_text">
                    {dateFilter === moment(new Date()).format('YYYY-MM-DD') ? "Today's spend time :" : 'Spend time :'}
                  </span>
                  <span className="time_text">{`${padToTwoDigits(Math.floor(totalMin / 60))}:${padToTwoDigits(
                    totalMin % 60
                  )}`}</span>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="sort-order-label">Sort Order</InputLabel>
                  <Select
                    labelId="sort-order-label"
                    id="sort-order-select"
                    value={sortOrder}
                    label="Sort Order"
                    onChange={handleSortChange}
                    size="small"
                  >
                    {SORT_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="log-type-label">Log Type</InputLabel>
                  <Select
                    labelId="log-type-label"
                    id="log-type-select"
                    value={logType}
                    label="Log Type"
                    onChange={handleLogTypeChange}
                    size="small"
                  >
                    {LOG_TYPES?.map((logType) => (
                      <MenuItem key={logType.VALUE} value={logType.VALUE}>
                        {logType.LABEL}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FilterModal
                  setProjectID={setProjectId}
                  setDate={setDate}
                  dateFilter={dateFilter}
                  setUserID={setUserID}
                  projectId={projectId}
                  userID={userID}
                  project={projectList}
                  teamUser={teamUserList}
                  users={userList}
                  setPage={setPage}
                />
              </div>
            </div>
            <Card className="main_container">
              <Divider />
              <AddLogsForm openLog={open} onClose={() => setOpen(false)} onNextStep={() => setOpen(false)} />

              <EditLogsForm
                open={editOpen}
                editTask={editTask}
                onClose={() => setEditOpen(false)}
                onNextStep={() => setEditOpen(false)}
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    hideCheckbox
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />
                  {loading ? (
                    <>
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                    </>
                  ) : (
                    <TableBody>
                      {tableData?.map((row) => (
                        <LogTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  )}
                </Table>
              </Scrollbar>
            </Card>
          </>
        ) : (
          <>
            <TimeSheet openNew={open} setOpenNew={setOpen} />
          </>
        )}
      </Container>
    </Page>
  );
}
