/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { imagesAll } from 'src/assets/Images_index';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { SkeletonProjectTab } from 'src/components/skeleton';
import AxiosAll from 'src/services/axiosAll';
import { TaskStatusTableToolbar } from 'src/sections/@dashboard/task_status/list';
import ReportDetail from '../today/reportDetail';
import EditMinutes from './EditMinutes';
import Minutes from './Minutes';

// Add this constant at the top with other constants
const VIEW_TYPES = [
  { value: 'startup', label: 'Startup Wise' },
  { value: 'project', label: 'Project Wise' },
];

export default function TaskCard({ row, page, setPage }) {
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [dataForMOM, setDataForMOM] = useState({});
  const [editMomData, setEditMomData] = useState({});
  const [momOpen, setMomOpen] = useState(false);
  const [momEditOPen, setMomEditOPen] = useState(false);
  const [userDropdownData, setUserDropdownData] = useState([]);
  const [statusDropdownData, setStatusDropdownData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const [userId, setUserId] = useState(search.get('user_id') || '');
  const [statusId, setStatusId] = useState(search.get('status_id') || '');
  const [weekId, setWeekId] = useState(search.get('week_id') || '');
  // Add this new state
  const [viewType, setViewType] = useState('startup');

  // const statusDropdown = [
  //   {
  //     id: 5,
  //     title: 'Completed',
  //   },
  //   {
  //     id: 4,
  //     title: 'In Review',
  //   },
  // ];

  const weeklyDropdown = [
    {
      id: 1,
      title: 'This Week',
    },
    {
      id: 2,
      title: 'Last Week',
    },
    {
      id: 3,
      title: 'This Month',
    },
  ];

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  // Modify the getProjects function to use viewType
  const getProjects = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `/projectTasks?startup_wise=${viewType === 'startup'}&user_id=${search.get('user_id') || userId}&status_id=${
        search.get('status_id') || statusId
      }&week_id=${search.get('week_id') || weekId}`,
      {},
      token
    );
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setTableData(data?.data);
      setLoading(false);
    }
  };

  // const getUsersDropdownData = async () => {
  //   const response = await AxiosAll('get', `/common/users`, null, token);
  //   const { data } = response;
  //   const newData = data.map((item) => {
  //     const { first_name, last_name, ...rest } = item;
  //     return { ...rest, label: `${first_name} ${last_name}` };
  //   });
  //   // setUserDropdown(newData);
  //   setUserDropdownData(newData);
  //   console.log('data restoreuser', data);
  // };

  const today = new Date().toISOString().split('T')[0];
  const loginDate = localStorage.getItem('loginDate');
  const userData = JSON.parse(localStorage.getItem('usersDropdownData'));

  const getUsersDropdownData = async () => {
    if (loginDate === today && userData) {
      setUserDropdownData(userData);
    } else {
      try {
        const response = await AxiosAll('get', `/common/users`, null, token);
        const { data } = response;
        const newData = data.map((item) => {
          const { first_name, last_name, ...rest } = item;
          return { ...rest, label: `${first_name} ${last_name}` };
        });

        localStorage.setItem('usersDropdownData', JSON.stringify(newData));
        localStorage.setItem('loginDate', today);

        setUserDropdownData(newData);
      } catch (error) {
        console.error('Error fetching users dropdown data:', error);
      }
    }
  };

  // const getStatusDropdownData = async () => {
  //   setLoading(true);
  //   const response = await AxiosAll('get', `/task-statuses?page=${page + 1}&yquery=${filterName}`, {}, token);
  //   const { data } = response;
  //   window.scrollTo(0, 0);
  //   if (data?.statusCode === 200) {
  //     setStatusDropdownData(data?.data?.data);
  //     setLoading(false);
  //   }
  // };

  const storedData = JSON.parse(localStorage.getItem('statusDropdownData'));
  const getStatusDropdownData = async () => {
    setLoading(true);
    if (storedData && loginDate === today) {
      setStatusDropdownData(storedData);
      setLoading(false);
    } else {
      try {
        const response = await AxiosAll('get', `/task-statuses?page=${page + 1}&query=${filterName}`, {}, token);
        const { data } = response;
        window.scrollTo(0, 0);
        if (data?.statusCode === 200) {
          localStorage.setItem('statusDropdownData', JSON.stringify(data?.data?.data));
          localStorage.setItem('loginDate', today);
          setStatusDropdownData(data?.data?.data);
        }
      } catch (error) {
        console.error('Error fetching status dropdown data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Call getUsersDropdownData and getStatusDropdownData once on component mount
    getUsersDropdownData();
    getStatusDropdownData();
  }, []);

  useEffect(() => {
    getProjects();
  }, [userId, statusId, weekId, viewType]);

  // Add handler for view type change
  const handleViewType = (e) => {
    setViewType(e.target.value);
  };

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(`${location.pathname}?user_id=${e?.target?.value}&status_id=${statusId}&week_id=${weekId}`);
    setUserId(e.target.value);
  };
  const handleStatus = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(`${location.pathname}?user_id=${userId}&status_id=${e?.target?.value}&week_id=${weekId}`);
    setStatusId(e.target.value);
  };
  const handleWeeek = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(`${location.pathname}?user_id=${userId}&status_id=${statusId}&week_id=${e?.target?.value}`);
    setWeekId(e.target.value);
  };
  return (
    <div className="task-card-section">
      <ReportDetail idToOpen={id} setOpen={setOpen} open={open} />
      {/* <TaskStatusTableToolbar filterName={filterName}  /> */}
      <Minutes
        projectData={dataForMOM}
        setOpen={setMomOpen}
        open={momOpen}
        userDropdownData={userDropdownData}
        getProjects={getProjects}
      />
      <EditMinutes
        projectData={editMomData}
        setOpen={setMomEditOPen}
        open={momEditOPen}
        userDropdownData={userDropdownData}
        getProjects={getProjects}
      />

      <Stack
        spacing={2}
        className="stack-center mom_margin"
        flexWrap="wrap"
        gap={2}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ py: { xs: 3, sm: 0 } }}
      >
        {/* View Type Dropdown */}
        <TextField
          fullWidth
          select
          label="View Type"
          value={viewType}
          onChange={handleViewType}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {VIEW_TYPES.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* User wise */}
        <TextField
          fullWidth
          select
          label="User"
          value={userId}
          onChange={handleUsers}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {userDropdownData?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.label}
            </MenuItem>
          ))}
        </TextField>
        {/* Status wise */}
        <TextField
          fullWidth
          select
          label="Status"
          value={statusId}
          onChange={handleStatus}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {statusDropdownData?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.name}
            </MenuItem>
          ))}
        </TextField>{' '}
        {/* Week wise */}
        <TextField
          fullWidth
          select
          label="Week wise"
          value={weekId}
          onChange={handleWeeek}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {weeklyDropdown?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          size="Large"
          onClick={() => window.open('/tasks/minuteofMeeting', '_blank')}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          style={{ marginLeft: 0 }}
        >
          Add M.O.M
        </Button>
      </Stack>
      {loading ? (
        <>
          <SkeletonProjectTab />
          <SkeletonProjectTab />
          <SkeletonProjectTab />
        </>
      ) : (
        tableData?.map((item, index) => (
          <Paper variant="outlined" className="task-card" sx={{ p: 2, bgcolor: 'grey.5008' }} key={index}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{item?.title}</Typography>
              <Typography variant="h5">{item?.total_spend_time}</Typography>
              <div onClick={() => window.open(`/tasks/minuteofMeeting`, '_blank')}>
                <Iconify
                  icon={'ic:baseline-plus'}
                  sx={{ cursor: 'Pointer' }}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </Box>

            <Box sx={{ height: '420px', overflowY: 'auto' }} className="box-height-overflow">
              {/* Mom Card */}
              {item?.minutes_of_meetings?.map((mom, index) => (
                <>
                  <Card
                    sx={{ p: 1.5, mt: 1 }}
                    key={index}
                    onClick={() => window.open(`/tasks/minuteofMeeting/${mom?.id}`, '_blank')}
                  >
                    <Typography variant="overline" sx={{ textTransform: 'initial' }} className="new-card-heading">
                      {mom?.title}
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Box>
                        <div>
                          <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                            Date:
                          </Typography>
                          <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>
                            {moment(mom?.date).format('D MMM YYYY')}
                          </Typography>
                        </div>
                        <div style={{ marginTop: '-5px' }}>
                          <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                            Time:
                          </Typography>
                          <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>
                            {mom?.time_format}
                          </Typography>
                        </div>
                      </Box>

                      <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 25, height: 25 } }}>
                        {mom?.assigned_users?.map((e, index) => (
                          <Avatar alt={e?.first_name} src={e?.profile_picture} key={index} />
                        ))}
                      </AvatarGroup>
                    </Stack>
                  </Card>
                </>
              ))}

              {/* Tasks Card */}
              {item?.tasks?.map((cardData, index) => (
                <Card
                  sx={{ p: 1.5, mt: 1, backgroundColor: cardData?.is_due ? 'error.light' : '#212B36' }}
                  key={index}
                  onClick={() => {
                    setId(cardData?.id);
                    setOpen(true);
                  }}
                >
                  <Grid container spacing={3}>
                    {/* Image */}
                    <Grid item xs={2} md={2}>
                      <Avatar
                        alt={cardData?.task_users?.full_name}
                        src={cardData?.task_users?.profile_picture || imagesAll.mainWhiteLogo}
                        sx={{ mr: 2 }}
                      />
                    </Grid>

                    {/* title and name */}
                    <Grid item xs={10} md={10}>
                      <Typography variant="overline" sx={{ textTransform: 'initial' }}>
                        {cardData?.task_users?.full_name}
                      </Typography>

                      {cardData?.task_priority?.id === 1 && cardData?.task_status?.id !== 5 ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'error'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : cardData?.is_due ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'primary'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 2', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 3', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* Time */}
                  <Box
                    Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Alloted Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.planned_time}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Spend Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.spend_time}
                    </Typography>
                  </Box>

                  {/* Dates */}
                  <Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Start Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.start_date).format('D MMM YYYY')}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Due Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.due_date).format('D MMM YYYY')}
                    </Typography>
                  </Box>

                  {/* Ticket */}
                  <Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Ticket
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.ticket}
                    </Typography>
                  </Box>

                  {/* Status and priority */}
                  <Box
                    sx={{
                      // display: 'grid',
                      // gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                      // justifyContent: 'space-between',
                      // display: 'flex',
                      // justifyContent: 'space-evenly',
                      // justifyItems: 'center',
                      // alignItems: 'flex-start',
                      display: 'flex',
                      gap: '10px',
                      '& > *:first-child': {
                        alignSelf: 'flex-start',
                      },
                      '& > *:last-child': {
                        alignSelf: 'flex-end',
                      },
                    }}
                  >
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_priority?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_priority?.name}
                    </Label>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_status?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_status?.name}
                    </Label>
                  </Box>
                </Card>
              ))}
            </Box>
          </Paper>
        ))
      )}
    </div>
  );
}
