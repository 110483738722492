import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../../routes/paths';
// components
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = async (data) => {
    try {
      // const baseUrl = 'http://localhost:4000';
      const baseUrl = 'https://pms.skunktest.work';
      const response = await AxiosAll('post', '/forget-password', {
        email: data.email,
        url: `${baseUrl}/change-password`,
      });
      const resData = response?.data;
      if (resData?.statusCode === 200) {
        enqueueSnackbar(resData?.message, { variant: 'success' });
        sessionStorage.setItem('email-recovery', data.email);
      } else {
        enqueueSnackbar(resData?.message, { variant: 'error' });
        reset();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          className="resetbtn"
          loading={isSubmitting}
        >
          Reset Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
