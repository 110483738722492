// import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { Divider, InputAdornment, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import AxiosAll from 'src/services/axiosAll';
import { useSnackbar } from 'notistack';
import { setCount } from 'src/redux/actions/reviewCount';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const JoinUserModal = ({ setLogin, login, LoginModal, setLoginModal }) => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { rcount } = useSelector((state) => state.reviewReducer);
  const [isBreak, setIsBreak] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [customTime, setCustomTime] = useState('00:00');
  const [defaultBreak, setDefaultbreak] = useState('');
  const [countdown, setCountdown] = React.useState(300); // 5 minutes countdown
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [active, setActive] = useState(false);
  const [startTime, setStartTime] = useState();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [day, setDay] = useState();
  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLogin(false);
    setCustomTime('00:00');
    setDefaultbreak('');
  };

  const handleBackButtonClick = () => {
    setIsBreak(false);
    // setBreak(false);
  };

  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, userDetails?.token);
    const { data } = response;
    dispatch(setCount(data));
  };

  const Login = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll('post', `/availability`, { availability_status_id: 2 }, userDetails?.token);
      const data = res.data.data.data;
      // setActivityList(data);
      if (res?.status === 201) {
        getCounts();
        setLogin(true);
        setLoginModal(false);
        enqueueSnackbar(`${res?.data?.data?.title} successfully!`, { variant: 'success' });
        window.open('https://us02web.zoom.us/j/6790281683?pwd=cG9vVTUxNnppYmU2alJ2ZHJyS2Mxdz09#success', '_blank');
      } else {
        setLogin(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Break
      </Button> */}
      <BootstrapDialog
        // onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={LoginModal}
      >
        {' '}
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Set your status as active
        </DialogTitle>
        <Divider sx={{ mx: 2 }} />
        {new Date().getDay() === 6 || new Date().getDay() === 7 ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ''
        )}
        <DialogContent dividers>
          <div>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Your current status in Unavailable, Please update your status as active!
            </Typography>
          </div>
        </DialogContent>
        <DialogActions className="break_btn-section">
          <>
            {new Date().getDay() === 6 || new Date().getDay() === 7 ? (
              <Button variant="contained" onClick={() => setLoginModal(true)} sx={{ textTransform: 'inherit' }}>
                No Thanks
              </Button>
            ) : (
              ''
            )}

            <Button
              onClick={Login}
              variant="contained"
              sx={{
                backgroundColor: login ? 'green' : 'green',
                '&:hover': {
                  backgroundColor: login ? 'green' : 'green',
                },
              }}
            >
              {loading ? 'Loading...' : 'Join'}
            </Button>
          </>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default JoinUserModal;
