/* eslint-disable no-debugger */
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import moment from 'moment';
// utils
import InputMask from 'react-input-mask';
import { fData } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';

// ----------------------------------------------------------------------

UserNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function UserNewEditForm({ isEdit, currentUser }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [roles, setRoles] = useState([]);
  const [rolesDropdown, setRolesDropdown] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUser] = useState([]);
  const [teamsDropdown, setTeamsDropdown] = useState([]);
  const navigate = useNavigate();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Name is required'),
    lastName: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email(),
    password: !isEdit && Yup.string().required('Password is required'),
    position: Yup.string().required('Position is required'),
    team: Yup.array().min(1, 'Please select at least one team').required('Team is required'),
    team_lead: Yup.string().required('Team lead is required'),
    manager: Yup.string().required('Manager is required.'),
    date_of_joining: Yup.string().required('date of joining is required.'),
    date_of_birth: Yup.string().required('date of birth is required.'),

    role: Yup.array().min(1, 'Please select at least one role').required('Role is required'),
    avatarUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
  });

  const defaultValues = useMemo(
    () => ({
      firstName: currentUser?.first_name || '',
      lastName: currentUser?.last_name || '',
      email: currentUser?.email || '',
      position: currentUser?.position || '',
      password: currentUser?.password || '',
      team: currentUser?.team || [],
      team_lead: currentUser?.user_team_lead_id || '',
      manager: currentUser?.user_manager_id || '',
      role: currentUser?.role || [],
      avatarUrl: currentUser?.profile_picture || '',
      status: currentUser?.status || 'active',
      profile_picture: currentUser?.profile_picture || '',
      working_hours: currentUser?.working_hours || '08:00',
      is_send_logs: currentUser?.is_send_logs || true,
      date_of_joining: moment(currentUser?.doj).format('yyyy-MM-DD'),
      date_of_birth: moment(currentUser?.dob).format('yyyy-MM-DD'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, users, isEdit]
  );

  // console.log("currentUser",currentUser)
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  // console.log('currentUser', currentUser);
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  // console.log('currentUser', values);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    } else if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser, users]);

  const getRoles = async () => {
    try {
      const res = await AxiosAll('get', '/roles', null, userDetails.token);
      const data = res.data.data;
      const rolesData = data.map((item) => ({ value: `${item.id}`, label: item.name }));
      setRoles(rolesData);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeams = async () => {
    try {
      const res = await AxiosAll('get', '/teams', null, userDetails.token);
      const data = res.data.data.data;
      const teamsData = data.map((item) => ({ value: `${item.id}`, label: item.name }));
      setTeams(teamsData);
    } catch (error) {
      console.log(error);
    }
  };
  const getUser = async () => {
    const res = await AxiosAll('get', '/common/users', {}, userDetails?.token);
    const { data } = res;
    const newData = data.map((item) => {
      const { first_name, last_name, ...rest } = item;
      return { ...rest, label: `${first_name} ${last_name}` };
    });
    setUser(newData);
  };
  useEffect(() => {
    getRoles();
    getTeams();
    getUser();
    return () => {
      reset(defaultValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const rolesDropdownData = roles.filter((item) => !values.role.some((role) => role.value == item.value));
    setRolesDropdown(rolesDropdownData);
    // eslint-disable-next-line eqeqeq
    const teamsDropdownData = teams.filter((item) => !values.team.some((team) => team.value == item.value));
    setTeamsDropdown(teamsDropdownData);
  }, [roles, teams, values.role, values.team]);
  const onSubmit = async () => {
    try {
      const team_ids = values.team.map((team) => +team.value);
      const role_ids = values.role.map((role) => +role.value);
      const formData = new FormData();
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('email', values.email);
      formData.append('password', values.password);
      formData.append('working_hours', values.working_hours);
      formData.append('is_send_logs', values.is_send_logs);
      formData.append('user_manager_id', values?.manager);
      formData.append('user_team_lead_id', values?.team_lead);
      formData.append('leave_approver', values?.manager);
      formData.append('doj', values?.date_of_joining);
      formData.append('dob', moment(values?.date_of_birth).format('yyyy-MM-DD'));
      if (isEdit) {
        if (values.profile_picture === values.avatarUrl) {
          formData.append('profile_picture', '');
        } else {
          formData.append('profile_picture', values.avatarUrl);
        }
      } else {
        formData.append('profile_picture', values.avatarUrl);
      }

      formData.append('position', values.position);
      team_ids.forEach((team_ids) => {
        formData.append('team_ids[]', team_ids);
      });

      role_ids.forEach((role_ids) => {
        formData.append('role_ids[]', role_ids);
      });
      const res = await AxiosAll(
        'post',
        isEdit ? `/users/${currentUser.id}` : '/users',
        formData,
        userDetails.token,
        'multipart/form-data'
      );
      if (res.data.statusCode === 200) {
        enqueueSnackbar(res.data.message, {
          variant: 'success',
        });
        navigate('/user/user-List');
      } else {
        enqueueSnackbar(res.data.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            {isEdit && (
              <Label
                color={values.status !== 'active' ? 'error' : 'success'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <div>
                <LabelStyle>First Name</LabelStyle>
                <RHFTextField name="firstName" placeholder="First Name" />
              </div>
              <div>
                <LabelStyle>Last Name</LabelStyle>
                <RHFTextField name="lastName" placeholder="Last Name" />
              </div>
              <div>
                <LabelStyle>Email</LabelStyle>
                <RHFTextField name="email" placeholder="Email" />
              </div>
              {!isEdit && (
                <div>
                  <LabelStyle>Password</LabelStyle>
                  <RHFTextField name="password" placeholder="Password" />
                </div>
              )}

              <div>
                <LabelStyle>Position</LabelStyle>
                <RHFTextField name="position" placeholder="Position" />
              </div>

              <div>
                <LabelStyle>Team</LabelStyle>
                <Controller
                  name="team"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={teamsDropdown}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip {...getTagProps({ index })} key={option.value} size="small" label={option.label} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                    />
                  )}
                />
              </div>

              <div>
                <LabelStyle>Team Lead</LabelStyle>
                <RHFSelect name="team_lead">
                  <option value={''}>Select</option>
                  {users.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.full_name}
                    </option>
                  ))}
                </RHFSelect>
              </div>

              <div>
                <LabelStyle>Manager</LabelStyle>
                <RHFSelect name="manager">
                  <option value={''}>Select</option>
                  {users.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.full_name}
                    </option>
                  ))}
                </RHFSelect>
              </div>

              <div>
                <LabelStyle>Role</LabelStyle>
                <Controller
                  name="role"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={rolesDropdown}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip {...getTagProps({ index })} key={option.value} size="small" label={option.label} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                    />
                  )}
                />
              </div>
              <div>
                <LabelStyle>Working Hours</LabelStyle>
                <Controller
                  name="working_hours"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <InputMask
                      maskChar={null}
                      mask="99:99"
                      value={field.value}
                      onChange={(e) => handleTimeChange(e, field.onChange)}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          error={!!error}
                          fullWidth
                          helperText={error?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography variant="body2">HH:MM</Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </div>
              <div>
                <LabelStyle>Date of joining</LabelStyle>
                <RHFTextField max={new Date().toISOString().split('T')[0]} name="date_of_joining" type="date" />
              </div>
              <div>
                <LabelStyle>Date of Birth </LabelStyle>
                <RHFTextField max={maxDate.toISOString().split('T')[0]} name="date_of_birth" type="date" />
              </div>
              <RHFSwitch name="is_send_logs" label="Log Show" />
            </Box>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create User' : 'Save Changes'}
              </LoadingButton>
              {isEdit && (
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={() => {
                    navigate('/user/user-List');
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
