import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

BulkTaskUpload.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getUserWiseData: PropTypes.func,
};

export default function BulkTaskUpload({ open, setOpen, getUserWiseData }) {
  const [csvFile, setCsvFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const outstandingSchema = Yup.object().shape({
    url: Yup.string().matches(
      /^https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?:\/[^\s]*)?$/,
      'Please enter a valid URL'
    ),
    project_id: Yup.string().required('Project is required'),
  });

  const { userDetails, projectList } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      url: '',
      project_id: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(outstandingSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileError('');

    if (!file) {
      setFileError('File is required');
      setCsvFile(null);
      return;
    }

    if (!file.name.endsWith('.csv')) {
      setFileError('Please upload a CSV file');
      setCsvFile(null);
      return;
    }

    setCsvFile(file);
  };

  const onSubmit = async (value) => {
    try {
      if (!csvFile) {
        setFileError('File is required');
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append('file', csvFile);
      formData.append('projectId', value.project_id);
      formData.append('referenceUrl', value.url);

      const response = await AxiosAll('post', '/tasks/bulk', formData, token, 'multipart/form-data');
      const { data } = response;

      if (data.statusCode === 201) {
        enqueueSnackbar(data?.message || 'Tasks uploaded successfully', { variant: 'success' });
        setOpen(false);
        reset(defaultValues);
        setCsvFile(null);
        if (getUserWiseData) {
          getUserWiseData();
        }
      } else {
        enqueueSnackbar(data?.message || 'Failed to upload tasks', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error uploading tasks:', error);
      enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset(defaultValues);
    setCsvFile(null);
    setFileError('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Add Bulk Task</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Select Project</LabelStyle>
                <select
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                  }}
                  {...methods.register('project_id')}
                >
                  <option value="">Select a project</option>
                  {projectList?.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.title}
                    </option>
                  ))}
                </select>
                {errors?.project_id && (
                  <Typography color="error" variant="body2">
                    {errors?.project_id?.message}
                  </Typography>
                )}
              </div>

              <div>
                <LabelStyle>Upload</LabelStyle>
                <input
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                  }}
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
                {fileError && (
                  <Typography color="error" variant="body2">
                    {fileError}
                  </Typography>
                )}
                {csvFile && (
                  <Typography variant="body2" sx={{ mt: 1, color: 'success.main' }}>
                    Selected file: {csvFile.name}
                  </Typography>
                )}
              </div>
            </Stack>
            <Stack spacing={3} mt={3}>
              <div>
                <LabelStyle>Reference Url</LabelStyle>
                <input
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                  }}
                  name="url"
                  type="text"
                  {...methods.register('url')}
                  onBlur={() => {
                    setValue('url', methods.getValues('url'));
                  }}
                />
                {errors?.url && (
                  <Typography color="error" variant="body2">
                    {errors?.url?.message}
                  </Typography>
                )}
              </div>
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              loading={loading}
              disabled={isSubmitting || !csvFile}
            >
              Submit
            </LoadingButton>
            <Button color="inherit" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
