import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { TableNoData, TableSkeleton } from 'src/components/table';
import EmptyContent from 'src/components/EmptyContent';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
import DetailReportViewModal from './DetailReportViewModal';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';

// sections
import { ProjectTableToolbar } from '../../sections/@dashboard/project/list';
// import AssignUsers from './dialogues/AssignUser';
// import AddManager from './dialogues/SyncManager';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'Name', label: 'User Name', align: 'left' },
  { id: 'Date', label: 'Date', align: 'left' },
  { id: 'Task', label: 'Task', align: 'left' },
  { id: 'SpendTime', label: 'Spend Time', align: 'left' },
];

// ----------------------------------------------------------------------

export default function ReportPrintPage({ projectId, userID, startDate, endDate, triggerReport }) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState();
  const [tableData, setTableData] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [newProjectId, setNewProjectId] = useState(projectId);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [singleProjectData, setSingleProjectData] = useState([]);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openManagerDialog, setOpenManagerDialog] = useState(false);
  const [userDropdownData, setUserDropdownData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const location = useLocation();

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const NewAccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(255, 99, 71, 0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(270deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const { enqueueSnackbar } = useSnackbar();

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const getReportDetail = async (userID, projectId, endDate, startDate) => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `/reports/overview?detailed_data=&user_id=${userID}&project_id=${projectId}&start_date=${moment(startDate).format(
        'YYYY-MM-DD'
      )}&end_date=${moment(endDate).format('YYYY-MM-DD')}`,
      {},
      token
    );
    const { data } = response;
    // window.scrollTo(0, 0);

    if (response?.status === 200) {
      setTableData(data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID) {
      getReportDetail(userID, projectId, endDate, startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReport]);

  const getUsersDropdownData = async () => {
    const response = await AxiosAll('get', `/common/users`, null, token);
    const { data } = response;
    const newData = data.map((item) => {
      const { first_name, last_name, ...rest } = item;
      return { ...rest, label: `${first_name} ${last_name}` };
    });
    // setUserDropdown(newData);
    setUserDropdownData(newData);
  };

  useEffect(() => {
    getUsersDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData?.length;

  return (
    <Page title="">
      {userID && (
        <DetailReportViewModal
          title={dialogTitle}
          projectId={newProjectId}
          open={open}
          onClose={() => setOpen(false)}
          date={dates}
          userId={userID}
        />
      )}
      <Container sx={{ mt: 3 }} maxWidth={themeStretch ? false : 'lg'} className="padding0">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="remove-shadow"
                style={{
                  whiteSpace: 'nowrap',
                  width: '50%',
                  backgroundColor: '#C8FACD',
                  color: '#212B36',
                  fontWeight: '800',
                }}
              >
                Project
              </TableCell>
              <TableCell
                className="remove-shadow"
                style={{ whiteSpace: 'nowrap', backgroundColor: '#C8FACD', color: '#212B36', fontWeight: '800' }}
              >
                Spend Time [HH:MM]
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        {loading ? (
          <>
            <Table className="skeleton_table">
              <TableHead>
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
              </TableHead>
            </Table>
          </>
        ) : (
          <>
            {tableData?.length > 0 ? (
              <>
                {tableData?.map((data, index) => (
                  <Accordion key={index} style={{ marginTop: '18px' }}>
                    <NewAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap', width: '50%' }}>
                              {data?.project_name}
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {data?.total_spend_time}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </NewAccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              className="remove-shadow"
                              style={{ whiteSpace: 'nowrap', width: '51%' }}
                            >
                              Date
                            </TableCell>
                            <TableCell align="left" className="remove-shadow" style={{ whiteSpace: 'nowrap' }}>
                              Spend Time
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {data?.dates?.map((item, index) => {
                          const date = Object.keys(item)[0];
                          const time = item[date];
                          return (
                            <TableBody key={index}>
                              <TableRow
                                hover
                                onClick={() => {
                                  setDialogTitle(data?.project_name);
                                  setDates(date);
                                  setNewProjectId(data?.project_id);
                                  setOpen(true);
                                }}
                                sx={{ cursor: 'pointer' }}
                              >
                                <TableCell align="left" style={{ whiteSpace: 'nowrap', width: '51%' }}>
                                  {moment(date).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                  {time}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })}
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            ) : (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <EmptyContent
                        title="No Data"
                        sx={{
                          '& span.MuiBox-root': { height: 160 },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </>
        )}
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   if (filterRole !== 'all') {
//     tableData = tableData.filter((item) => item.role === filterRole);
//   }

//   return tableData;
// }
